<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_5">
      램 SMS 수신 동의<router-link to="" @click.native="$router.back()"
        ><img src="@/assets/images/sub/close.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="terms_common">
      <h4>램 문자 발송방침</h4>

      <!---->
      <p>
        리뷰앤메이크머니(주)는 자체의 알림창을 통해 또는 외부의 문자 메시지를
        통해 램 이용자에게 메시지를 보낼 수 있습니다. 이는 램 회원의 휴대폰
        인증, 상품/서비스 거래, 리뷰어 채널 등록, 판매자 채널 등록, 포인트 지급,
        찜한 리뷰어의 새로운 리뷰, 리뷰어와 판매자 간 메시지 전송, 램의 각종
        정책 공지 등등 램의 기능을 원활히 하기 위한 조치이며, 이용자의 편의를
        증진시키 위한 방안입니다. 이런조치들 외에, 회사는 램 이용자에게 광고성
        문자를 보내지 않습니다. 다만, 회사 정책상 필요에 의해 회사가 광고성
        문자를 보낼 경우 회사는 이용자의 동의를 얻어 이를 시행합니다.
      </p>
      <p></p>
    </div>

    <!---->
  </div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("common/setFooter", {
      viewFooter: false,
    });
  },
  metaInfo() {
    return {
      title: `${process.env.VUE_APP_APP_NAME}`,
      meta: [
        {
          vmid: "title",
          name: "title",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "author",
          name: "author",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "description",
          name: "description",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `${process.env.VUE_APP_API_URL}${this.$route.fullPath}`,
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        { vmid: "og:type", property: "og:type", content: "website" },
        { vmid: "og:image", property: "og:image", content: "" },
      ],
    };
  },
};
</script>

<style></style>
